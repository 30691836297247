<!--
 * @author: JJ
 * @since: 2024-11-01
 * Home.vue
-->
<template>
  <div>
    <div class="container1 bg-normal flex-box flex-center" v-show="first">
      <img src="../assets/icon/load_logo.png" class="logo" alt="">
      <div class="progress_nav  lh14 colf3">
        <div class="pr logo_text">
          <div class="over_per" :style="{ 'width': percentage + '%' }"><span>LOADING. CAR FILM MASTER IS
              COMING.</span>
          </div>
          <div>LOADING. CAR FILM MASTER IS COMING.</div>
        </div>
        <el-progress class="mt30" :percentage="percentage" :stroke-width="5" :color="'#FB471F'" :show-text="false" />
      </div>
    </div>
    <div class="container bg-normal" v-show="!first">
      <header>
        <div class="header_nav flex-box flex-between">
          <img src="../assets/icon/load_logo_header.png" class="logo" alt="">
          <div class="more flex-box flex-center" :class="showpop == true ? 'more_ani' : ''" @click="showpop = !showpop">
          </div>
        </div>
      </header>
      <aside>
        <div class="left_nav">
          <div class="fs14 lh14" :class="nindex == index ? 'active' : ''" v-for="(item, index) in leftList"
            @click="chooseNav(index + 1)" :key="index">
            <p>{{ item }}</p>
          </div>
        </div>
        <div class="left_nav_mobile">
          <div :class="nindex == index ? 'active' : ''" v-for="(item, index) in leftList" @click="chooseNav(index + 1)"
            :key="index">
            <p></p>
          </div>
        </div>
      </aside>

      <aside>
        <div class="home cp" @click="goBack"><span></span></div>
      </aside>
      <div class="popup-mask" :class="showpop ? 'popup-mask_visible' : ''" @click="showpop = false"></div>
      <div class="popup-wrap" :class="showpop ? 'popup-wrap_visible' : ''">
        <div class="popup ">


          <div class="right">
            <ul class="col6a fw500">

              <li :class="index == choosepopindex ? 'active' : 'fs15 lh16'" v-for="(item, index) in popleft"
                @mouseenter="choosepop(index, 0)" :key="index">
                <router-link :to="item.url" class="flex-grow-1 cp" v-if="item.url != '/DealerPortal'">{{ item.name
                  }}</router-link>
                <router-link :to="item.url" class="flex-grow-1 cp" v-if="item.url == '/DealerPortal' && shop_info">{{
                  item.name }}</router-link>
                <div v-if="item.url == '/DealerPortal' && !shop_info" @click="gologin">{{ item.name }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 广告 -->
      <div class="popup-mask ad" :class="showad ? 'popup-mask_visible' : ''"></div>
      <div class="popup-wrap ad" :class="showad ? 'popup-wrap_visible' : ''">
        <div class="ad_pop pc">
          <a href="https://www.joyvie.com/ContactUs" target="_blank" >
            <img src="../assets/icon/ad.png" class="ad_img" alt="">
          </a>

          <img src="../assets/icon/ad_close.png" class="ad_close" @click="closeAd" alt="">

        </div>
        <div class="ad_pop mob">
          <a href="https://www.joyvie.com/ContactUs" target="_blank" >
          <img src="../assets/icon/ad_mob.png" class="ad_img_mob" alt="">
          </a>
          <img src="../assets/icon/ad_close.png" class="ad_close_mob" @click="closeAd" alt="">

        </div>
      </div>

      <!-- 主要内容 -->
      <aside class="pc">
        <div class="down_more" v-if="activeIndex != 7">
          <div class="anim">
            <div class="animation"></div>
          </div>
          <p>SCROLL DOWN</p>
        </div>
      </aside>
      <section>
        <swiper :direction="'vertical'" :effect="'creative'" :touchRatio="2"
          :creativeEffect="{ prev: { shadow: true, translate: [0, -400, -1], }, next: { shadow: true, translate: [0, '100%', 0], }, }"
          ref="swiper2" @swiper="onSwiper" :speed="600" :mousewheel="true" @slideChange="onSlideChange"
          :modules="modules" class="swiper">
          <swiper-slide class="swiper_item">
            <div class="swiper_item_div pr pc" :class="activeIndex == 0 ? 'active' : ''"
              :style="{ backgroundImage: `url(${bg1})` }">
              <video :src="activeIndex == 0 ? videourl : ''" loop :autoplay="activeIndex == 0" class="video_box"
                muted></video>
            </div>
            <div class="swiper_item_div pr mob" :class="activeIndex == 0 ? 'active' : ''"
              :style="{ backgroundImage: `url(${mbg1})` }">
              <video :src="activeIndex == 0 ? videourl : ''" loop :autoplay="activeIndex == 0" class="video_box"
                muted></video>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper_item">
            <div class="swiper_item_div pt100 pc" :class="activeIndex == 1 ? 'active' : ''"
              :style="{ backgroundImage: `url(${bg2})` }">
              <div class="delay-1s animate__animated w1126"
                :class="activeIndex == 1 ? 'animate__fadeIn' : 'animate__fadeOut'">
                <div class="colf fwb fs30 lh30">Auto Film</div>
                <div class="colf_8 fs16 mt15 shadow">Joyvie’s PPF delivers top-tier solutions for vehicle surface
                  protection
                  and aesthetics.</div>
                  <div class="flex-box">
                    <router-link class="explore1" to="/PPF">Explore ></router-link>
                    <a href="https://www.joyvie.com/ContactUs" class="explore1 botton1 poa ml20" to="/PPF">Request Sample</a>

                  </div>
              </div>
              <div class="dot_nav1 delay-1s animate__animated dot"
                :class="activeIndex == 1 ? 'animate__fadeIn' : 'animate__fadeOut'" @mouseenter="dotshow1 = true"
                @mouseleave="dotshow1 = false">
                <router-link to="/PPF" class="dot_pop flex-box" v-if="dotshow1">
                  <img src="../assets/icon/home_bg1.png" alt="">
                  <div class="flex-grow-1 pl15">
                    <div class="fw500 fs20 colf lh20">Window Film</div>
                    <div class="mt25 colfb fs14 lh14">MORE+</div>
                  </div>
                </router-link>
              </div>
              <div class="dot_nav2 delay-1s animate__animated dot"
                :class="activeIndex == 1 ? 'animate__fadeIn' : 'animate__fadeOut'" @mouseenter="dotshow2 = true"
                @mouseleave="dotshow2 = false">
                <router-link to="/PPF" class="dot_pop flex-box" v-if="dotshow2">
                  <img src="../assets/icon/home_bg1.png" alt="">
                  <div class="flex-grow-1 pl15">
                    <div class="fw500 fs20 colf lh20">PPF</div>
                    <div class="mt25 colfb fs14 lh14">MORE+</div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="swiper_item_div pt100 mob" :class="activeIndex == 1 ? 'active' : ''"
              :style="{ backgroundImage: `url(${mbg2})` }">
              <div class="delay-1s animate__animated w1126"
                :class="activeIndex == 1 ? 'animate__fadeIn' : 'animate__fadeOut'">
                <div class="colf fwb fs30 lh30">Auto Film</div>
                <div class="colf_8 fs12 shadow mt20">Joyvie’s PPF delivers top-tier solutions for vehicle surface
                  protection
                  and aesthetics.</div>
                  <div class="flex-box">
                    <router-link class="explore1" to="/PPF">Explore ></router-link>
                    <a href="https://www.joyvie.com/ContactUs" target="_blank" class="explore1 botton1 poa" to="/PPF">Request Sample</a>

                  </div>
              </div>
              <div class="dot_nav1 phone delay-1s animate__animated dot"
                :class="activeIndex == 1 ? 'animate__fadeIn' : 'animate__fadeOut'" @mouseenter="dotshow1 = true"
                @mouseleave="dotshow1 = false">
                <router-link to="/PPF" class="dot_pop  flex-box" v-if="dotshow1">
                  <img src="../assets/icon/home_bg1.png" alt="">
                  <div class="flex-grow-1 pl15">
                    <div class="fw500 fs20 colf lh20">Window Film</div>
                    <div class="mt10 colfb fs12 lh14">MORE+</div>
                  </div>
                </router-link>
              </div>
              <div class="dot_nav2 phone delay-1s animate__animated dot"
                :class="activeIndex == 1 ? 'animate__fadeIn' : 'animate__fadeOut'" @mouseenter="dotshow2 = true"
                @mouseleave="dotshow2 = false">
                <router-link to="/PPF" class="dot_pop  flex-box" v-if="dotshow2">
                  <img src="../assets/icon/home_bg1.png" alt="">
                  <div class="flex-grow-1 pl15">
                    <div class="fw500 fs15 colf lh20">PPF</div>
                    <div class="mt10 colfb fs12 lh14">MORE+</div>
                  </div>
                </router-link>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper_item">
            <router-link class="swiper_item_div pt100 pc" :class="activeIndex == 2 ? 'active' : ''"
              :style="{ backgroundImage: `url(${bg3})` }" :to="{ path: '/Software' }">
              <router-link class="explore1 botton" :to="{ path: '/Software' }">Explore ></router-link>
              <!-- <div class="delay-1s animate__animated"
                :class="activeIndex == 2 ? 'animate__fadeIn' : 'animate__fadeOut'">
                <div class="colf fwb fs30 lh30">Precut Software</div>
              
                <div class="colf_8 fs16 shadow mt20">30,000+ full car patterns, and increasing daily. EASY! PRECISE!
                  Free
                  Trial!</div>
                <router-link class="explore" :to="{ path: '/Software' }">Explore ></router-link>
              </div> -->

            </router-link>
            <div class="swiper_item_div pt100 mob" :class="activeIndex == 2 ? 'active' : ''"
              :style="{ backgroundImage: `url(${mbg3})` }">
              <router-link class="explore1 botton" :to="{ path: '/Software' }">Explore ></router-link>
              <!-- <div class="delay-1s animate__animated"
                :class="activeIndex == 2 ? 'animate__fadeIn' : 'animate__fadeOut'">
                <div class="colf fwb fs30 lh30">Precut Software</div>
              
                <div class="colf_8 fs12 shadow mt15">30,000+ full car patterns, and increasing daily. EASY! PRECISE!
                  Free
                  Trial!</div>
                <router-link class="explore" :to="{ path: '/Software' }">Explore ></router-link>
              </div> -->

            </div>
          </swiper-slide>
          <swiper-slide class="swiper_item">
            <div class="swiper_item_div pt100 pc" :class="activeIndex == 3 ? 'active' : ''"
              :style="{ backgroundImage: `url(${bg4})` }">
              <!--  :style="{ backgroundImage: `url(${bg4})` }" -->
              <div class="tc_nav w1126">
                <div class="delay-1s animate__animated pr z2">
                  <!-- <div class="flex-box w960"> -->
                  <div class="colf fwb fs30 lh30">Plotter</div>
                  <!-- <router-link :to="{ path: '/Software' }" class="more"> MORE+</router-link> -->
                  <!-- </div>] -->
                  <div class="colf_8 fs16 shadow mt20 ">Our patented plotter specifically designed for PPF and
                    window tinting installation.<br />“Peeling” and “rewinding”, with reliable quality.</div>
                  <router-link class="explore1" :to="{ path: '/Plotter' }">Explore ></router-link>
                </div>

                <!-- <img src="../assets/icon/bg_ra.png" class="bg_ra animate__animated delay-1s"
                  :class="activeIndex == 3 ? 'animate__fadeIn' : 'animate__fadeOut'" alt="">
                <img src="../assets/icon/bg_plotter.png" class="bg_plotter animate__animated delay-1s"
                  :class="activeIndex == 3 ? 'animate__fadeIn' : 'animate__fadeOut'" alt=""> -->
              </div>

            </div>
            <div class="swiper_item_div pt100 mob" :class="activeIndex == 3 ? 'active' : ''"
              :style="{ backgroundImage: `url(${mbg4})` }">
              <div class="tc_nav w1126">
                <div class="delay-1s animate__animated pr z2">
                  <div class="colf fwb fs30 lh30">Plotter</div>
                  <div class="colf_8 fs12 shadow mt15 ">Our patented plotter specifically designed for PPF and
                    window tinting installation.<br />“Peeling” and “rewinding”, with reliable quality.</div>
                  <router-link class="explore1" :to="{ path: '/Plotter' }">Explore ></router-link>
                </div>
              </div>

            </div>
          </swiper-slide>
          <swiper-slide class="swiper_item">
            <div class="pc" style="height: 100vh;">
              <router-link to="/Training" class="swiper_item_div ra1 pt100 " :class="activeIndex == 4 ? 'active' : ''"
                :style="{ backgroundImage: `url(${bg10})` }"></router-link>
            </div>
            <div class="mob" style="height: 100vh;">
              <router-link to="/Training" class="swiper_item_div ra1 pt100 " :class="activeIndex == 4 ? 'active' : ''"
                :style="{ backgroundImage: `url(${mbg10})` }"></router-link>
            </div>
            <!-- <div class="pc" style="height: 100vh;">
              <div class="swiper_item_div flex-box" :class="activeIndex == 4 ? 'active' : ''"
                :style="{ backgroundImage: `url(${bg5})` }">
                <div class="delay-1s w470 animate__animated "
                  :class="activeIndex == 4 ? 'animate__fadeIn' : 'animate__fadeOut'">
                  <div class="colf fwb fs40 lh40">Training</div>
                  <div class="mt70 fs30 colf">Become a Joyvie PPF installer</div>
                  <div class="colf_8 mt30 fs16 lh26">Under the guidance of a professional PPF installer, Joyvie Training
                    provides detailed guidance on methods, knowledge, and years of practical experience to help you
                    become
                    a
                    certified Joyvie PPF installer.
                  </div>
                  <div class="bb"></div>
                  <div class="w470 mt55"></div>
                  <div class="w470 flex-box ">
                    <div class="tc flex-grow-1 number_nav">
                      <p>position</p>
                      <div class="number" v-if="activeIndex == 4">
                        <AnimatedNumber :duration="2000" :key="ani" :from="0" :to="115"></AnimatedNumber>
                      </div>
                      <div class="title">Number of trainers</div>
                    </div>
                    <div class="tc flex-grow-1 number_nav">
                      <p>individual</p>
                      <div class="number" v-if="activeIndex == 4">
                        <AnimatedNumber :duration="2000" :key="ani" :from="0" :to="60"></AnimatedNumber>
                      </div>
                      <div class="title">Covering countries</div>
                    </div>
                    <div class="tc flex-grow-1 number_nav">
                      <p>year</p>
                      <div class="number" v-if="activeIndex == 4">
                        <AnimatedNumber :duration="2000" :key="ani" :from="0" :to="15"></AnimatedNumber>
                      </div>
                      <div class="title">Training experience</div>
                    </div>
                  </div>
                  <router-link to="/Training" class="explore" style="margin-top: 80px;">Explore ></router-link>
                </div>
                <img :src="bg11" class="train_img" alt="">
              </div>
            </div>
            <div class="mob">
              <router-link to="/Training" class="swiper_item_div ra1 pt100" :class="activeIndex == 4 ? 'active' : ''" :style="{ backgroundImage: `url(${bg10})` }">
                <div class="delay-1s w470 animate__animated"
                  :class="activeIndex == 4 ? 'animate__fadeIn' : 'animate__fadeOut'">
                  <div class="flex-box flex-col">
                    <div class="colf fwb fs30 lh20">Training</div>
                    <div class="mt20 fs15 colf">Become a Joyvie PPF installer</div>
                    <div class="colf_8 mt10 fs12 lh15">Under the guidance of a professional PPF installer, Joyvie
                      Training
                      provides detailed guidance on methods, knowledge, and years of practical experience to help you
                      become
                      a
                      certified Joyvie PPF installer.
                    </div>
                    <div class="bb"></div>
                    <div class="w470 mt55"></div>
                    <div class="w470 flex-box ">
                      <div class="tc flex-grow-1 number_nav">
                        <p>position</p>
                        <div class="number" v-if="activeIndex == 4">
                          <AnimatedNumber :duration="2000" :key="ani" :from="0" :to="115"></AnimatedNumber>
                        </div>
                        <div class="title">Number of trainers</div>
                      </div>
                      <div class="tc flex-grow-1 number_nav">
                        <p>individual</p>
                        <div class="number" v-if="activeIndex == 4">
                          <AnimatedNumber :duration="2000" :key="ani" :from="0" :to="60"></AnimatedNumber>
                        </div>
                        <div class="title">Covering countries</div>
                      </div>
                      <div class="tc flex-grow-1 number_nav">
                        <p>year</p>
                        <div class="number" v-if="activeIndex == 4">
                          <AnimatedNumber :duration="2000" :key="ani" :from="0" :to="15"></AnimatedNumber>
                        </div>
                        <div class="title">Training experience</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-box mt30">
                  <div class="flex-grow-1">
                    <router-link to="/Training" class="explore" style="margin-top: 0;">Explore ></router-link>
                  </div>
                  <img :src="bg11" class="train_img" alt="">
                </div>
              </router-link>
            </div> -->
          </swiper-slide>
          <swiper-slide class="swiper_item">
            <div class="swiper_item_div pt100 pr" :class="activeIndex == 5 ? 'active' : ''"
              :style="{ backgroundImage: `url(${safeList[dindex - 1].url})` }">
              <div class="delay-1s tc w1126 animate__animated animate__fadeIn">
                <div class="w600 pr">
                  <div class="fwb colf fs30 lh40 delay-1s animate__animated " :class="move ? 'animate__fadeIn' : ''">{{
                    safeList[dindex - 1].name }}</div>
                  <div class="colf_8 fs16 shadow delay-1s mt15 animate__animated"
                    :class="move ? 'animate__fadeIn' : ''">
                    <span>{{ safeList[dindex - 1].content }}</span>
                  </div>
                  <div class="fix">
                    <router-link to="/SafeIn" class="safein_link">Safe-In Details</router-link>
                    <div class="technical-specs__menu" :data-current="dindex">
                      <div class="ellipse"></div>
                      <div class="dots">
                        <div class="dot" @click="chooseDot(index + 1)"
                          :class="[dindex == index + 1 ? 'current' : '', index == 8 ? 'org' : '']"
                          :data-index="index + 1" v-for="(item, index) in safeList" :key="index"></div>
                      </div>
                      <div class="labels">
                        <span class="label" @click="chooseDot(index + 1)"
                          :class="[move ? 'moving' : '', dindex == index + 1 ? 'current' : '', index == 8 ? 'org' : '']"
                          :data-index="index + 1" v-for="(item, index) in safeList" :key="index">{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </swiper-slide>
          <swiper-slide class="swiper_item">
            <div class="pc" style="height: 100vh;">
              <router-link :to="{ path: '/WarrantyCheck' }" class="swiper_item_div ra1 pt100 "
                :class="activeIndex == 6 ? 'active' : ''" :style="{ backgroundImage: `url(${bg9})` }">
                <div class="fs40 colf lh40 fwb animate__animated"
                  :class="activeIndex == 6 ? 'animate__fadeIn' : 'animate__fadeOut w470'">QUALITY ASSURANCE</div>
                <div class="delay-1s animate__animated pr mt30 w470 fs16 lh32 colf">
                  Joyvie’s warranty system exemplifies our unwavering commitment to product excellence ensuring every
                  customer receives unparalleled assurance and superior
                </div>
                <div class="delay-1s animate__animated pr mt120 w470 fs22 lh48 medium colf" v-if="warrant_years">
                  <p>As of {{ nowTime }}, </p>
                  <p><span class="fwb fs50 coleb5 pr10">{{ warrant_years }}</span>world-wide clients </p>
                  <p>have chosen JOYVIE products.</p>
                </div>

                <!-- <div class="pc">
              
            </div>
            <div class="mob">
              <div class="delay-1s animate__animated pr flex">
                <div>
                  <div class="pl21 fs30 colf fwb lh40 animate__animated"
                    :class="activeIndex == 6 ? 'animate__fadeIn' : 'animate__fadeOut'">Warranty</div>
                  <div class="mt20 info_left delay-1s animate__animated"
                    :class="activeIndex == 6 ? 'animate__fadeIn' : 'animate__fadeOut'">
                    <img src="../assets/icon/pre.png" alt="">
                    <div class="fs12 pl15"><span v-html="p_info"></span></div>
                    <img src="../assets/icon/last.png" class="mr0" alt="">
                   
                    <router-link class="explore" :to="{ path: '/WarrantyCheck' }" style="margin-top: 10px;">Explore
                      ></router-link>
                  </div>
                  <div class="mt20">
                    <div class="img_warranty" :style="{ backgroundImage: `url(${img_warranty})` }"></div>
                  </div>
                </div>

              </div>
            </div> -->

              </router-link>
            </div>
            <div class="mob" style="height: 100vh;">
              <router-link :to="{ path: '/WarrantyCheck' }" class="swiper_item_div ra1 "
                :class="activeIndex == 6 ? 'active' : ''" :style="{ backgroundImage: `url(${mbg9})` }">
                <div class="w300 h100 flex flex-col flex-center">
                  <div class="fs38 colf lh40 fwb animate__animated"
                    :class="activeIndex == 6 ? 'animate__fadeIn' : 'animate__fadeOut '">QUALITY ASSURANCE</div>
                  <div class="delay-1s animate__animated pr mt20 fs12 lh20 colf">
                    Joyvie’s warranty system exemplifies our unwavering commitment to product excellence ensuring every
                    customer receives unparalleled assurance and superior
                  </div>
                  <router-link :to="{ path: '/WarrantyCheck' }"
                    class="delay-1s animate__animated pr read_more tc fwb mt10">
                    READ MORE
                  </router-link>
                  <img src="../assets/icon/N1.png" class="img290 mt30" alt="">
                  <div class="delay-1s animate__animated pr mt50 fs12 lh26 tc medium col152" v-if="warrant_years">
                    <p>As of {{ nowTime }}, </p>
                    <p><span class="fwb fs30 coleb5 pr10">{{ warrant_years }}</span>world-wide clients </p>
                    <p>have chosen JOYVIE products.</p>
                  </div>
                </div>
              </router-link>


            </div>
          </swiper-slide>

          <swiper-slide class="swiper_item">
            <div class="swiper_item_div pt100" :class="activeIndex == 7 ? 'active' : ''"
              :style="{ backgroundImage: `url(${bg8})` }">
              <!-- <div class="delay-1s animate__animated  flex flex-center flex-col"
                :class="activeIndex == 7 ? 'animate__fadeIn' : 'animate__fadeOut'"> -->
              <!-- <div class="fwb colf fs40 lh40">News and Blogs</div>
                <ul class="mt70 mob_pr30">
                  <li class="news_item" v-for="(item, index) in newsList">
                    <router-link :to="{ name: 'blogInfo', query: { id: item.id } }">
                      <div class="img_nav flex flex-align-end" :style="{ backgroundImage: `url(${item.image})` }">
                        <div class="tips" v-for="(items, index1) in item.label_arr">{{ items }}</div>
                        <div>{{ item.createtime }}</div>
                      </div>
                      <p class="m-ellipsis">{{ item.title }}</p>
                      <span class="m-ellipsis-l2">{{ item.desc }}</span>
                    </router-link>
                  </li>
  
                </ul>
                <router-link :to="{ path: '/Blog' }" class="more1 mr0 mt80 mob_mr0">MORE+</router-link> -->
              <div class="delay-1s animate__animated"
                :class="activeIndex == 7 ? 'animate__fadeIn' : 'animate__fadeOut'">
                <!-- <div class="flex-box "> -->
                <div class="colf fwb fs30 lh30 w1126">News and Blogs</div>
                <!-- <router-link :to="{ path: '/Blog' }" class="more"> MORE+</router-link> -->
                <!-- </div> -->
                <div class="colf_8 fs16 shadow mt20 w1126">The Joyvie gallery showcases a series of videos and image
                  case
                  studies,
                  offering a glimpse into Joyvie's commitment to excellence. </div>
                <router-link class="explore" :to="{ path: '/Blog' }">Explore ></router-link>

                <ul class="mt70 pc">
                  <li class="news_item" v-for="(item1, index1) in blog" :key="index1">
                    <router-link :to="{ name: 'blogInfo', query: { id: item1.id } }">
                      <div class="img_nav flex flex-align-end" :style="{ backgroundImage: `url(${item1.image})` }">

                        <template v-for="(item2, index2) in item1.label_arr" :key="index2">
                          <div class="tips">{{ item2 }}</div>


                        </template>
                        <div>{{ item1.createtime }}</div>
                      </div>
                      <p class="m-ellipsis">{{ item1.title }}</p>
                      <span class="m-ellipsis-l2">{{ item1.desc }}</span>
                    </router-link>
                  </li>

                </ul>
                <ul class="mt70 mob">
                  <li class="news_item" v-for="(item1, index1) in blog" :key="index1">
                    <router-link :to="{ name: 'blogInfo', query: { id: item1.id } }" v-if="index1 < 1">
                      <div class="img_nav flex flex-align-end" :style="{ backgroundImage: `url(${item1.image})` }">
                        <template v-for="(item2, index2) in item1.label_arr" :key="index2">
                          <div class="tips">{{ item2 }}</div>

                        </template>
                        <div>{{ item1.createtime }}</div>
                      </div>
                      <p class="m-ellipsis">{{ item1.title }}</p>
                      <span class="m-ellipsis-l2">{{ item1.desc }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
              <!-- </div> -->
            </div>
          </swiper-slide>
        </swiper>
      </section>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, reactive, computed, onBeforeUnmount } from "vue"
import { Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax, EffectCreative, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import { useRouter } from 'vue-router'
const router = useRouter()
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import 'swiper/css/effect-creative';
import popup from "../components/popup.vue";
import { apiGetIndex, apiBaseInfomation, apigetinfos, apigetWarrantys } from "../request/api";
import { useHead } from "@unhead/vue";
const modules = [Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax, EffectCreative, Mousewheel];
const bg1 = ref(require('../assets/icon/home_bg1.png'))
const mbg1 = ref(require('../assets/icon/home_bg1_mob.png'))
const bg2 = ref(require('../assets/icon/home_bg2.png'))
const mbg2 = ref(require('../assets/icon/home_bg2_mob.png'))
console.log('111', mbg2.value);
const news_img1 = ref(require('../assets/icon/home_news1.png'))
const news_img2 = ref(require('../assets/icon/home_news2.png'))
const bg3 = ref(require('../assets/icon/home_bg2.jpg'))
const mbg3 = ref(require('../assets/icon/home_bg2_mob.jpg'))
const bg4 = ref(require('../assets/icon/home_bg4.jpg'))
const mbg4 = ref(require('../assets/icon/home_bg4_mob.jpg'))
const bg5 = ref(require('../assets/icon/home_bg5.png'))
const bg6 = ref(require('../assets/icon/home_bg6.png'))
const bg8 = ref(require('../assets/icon/home_bg7.jpg'))
const bg9 = ref(require('../assets/icon/bgn.jpg'))
const mbg9 = ref(require('../assets/icon/bgn_mob.jpg'))
const bg7 = ref(require('../assets/icon/bg_gallery.png'))
const bg11 = ref(require('../assets/icon/blog_item.png'))
const bg10 = ref(require('../assets/icon/home_bg9.jpg'))
const mbg10 = ref(require('../assets/icon/home_bg9_mob.jpg'))

const img_warranty = ref(require('../assets/icon/img_warranty.png'))
const bg_gallery = ref(require('../assets/icon/bg_gallery.png'))
let dotshow1 = ref(false)
let dotshow2 = ref(false)
let activeIndex = ref(0)
const leftList = ref(['Auto Film', 'Precut Software', 'Plotter', 'Training', 'Safe-IN', 'Warranty', 'News and Blogs'])
let uswiper = ref(undefined);
let uswiper1 = ref(undefined);
let nindex = ref(-1)
let showpop = ref(false)
let videourl = ref(require('../assets/videos/home_video1.mp4'))
let popleft = ref([{ name: 'HOME', url: '/' },
{ name: 'ABOUT JOYVIE', url: '/blog', children: [{ 'name': 'Blog', url: '/blog' }, { name: 'About Us', url: '/aboutUs' }, { name: 'Core Values', url: '/coreValues' }] },
{ name: 'PRODUCTS', url: '/PPF', children: [{ name: 'PPF', url: '/PPF' }, { name: 'Precut Software', url: '/Software' }, { name: 'Plotter', url: '/Plotter' }, { name: 'Tools', url: '/Tools' }] },
{ name: 'TECHNICAL CENTER', url: '/WarrantyCheck', children: [{ name: 'Warranty Check', url: '/WarrantyCheck' }, { name: 'FAQ', url: '/FAQ' }, { name: 'Download Center', url: '/DownloadCenter' }] },
{ name: 'NETWORK', url: '/PartnerSearch', children: [{ name: 'Partner Search', url: '/PartnerSearch' }, { name: 'Become Partner', url: '/BecomePartner' }, { name: 'Collection', url: '/Collection' }] },
{ name: 'CONTACT US', url: '/ContactUs', children: [] },
{ name: 'DEALER PORTAL', url: '/DealerPortal', children: [] },
])
let popright = ref([{ name: 'Home', children: [], url: '/' }]);
const p_info = ref('7 Year long reliable warranty! \nNEVER COMPROMISE to low quality and over promise!\nLearn our warranty Check your warranty!')
let safeList = ref(
  [{ name: 'In-store check', content: 'We will inspect the paintwork of every part for issues such as scratches, dents, or paint peeling. These issues will be carefully recorded and communicated with the vehicle owner.', url: require('../assets/icon/home_bg9.png') },
  { name: '3 cycles of cleaning', content: 'Before the installation, we will perform a deep cleaning using professional methods to ensure that the installation surface is free of dust and impurities.', url: require('../assets/icon/home_bg1.png') },
  { name: 'Car protection', content: 'After a thorough cleaning of the vehicle, we will apply protective measures to the windows, tires, steering wheel, seats, and other areas to ensure that these parts are not damaged or soiled during the installation process.', url: require('../assets/icon/home_bg9.png') },
  { name: 'Installation', content: "The pre-cut film of vehicle pattern is installed on each part of the vehicle by using JOYVIE's unique 9-step installation methods, ensuring high-quality installation.", url: require('../assets/icon/home_bg1.png') },
  { name: 'Rough Check', content: 'After installing the film on each part of the vehicle, we conduct a thorough inspection to check for any air bubbles, dust particles, or other imperfections.', url: require('../assets/icon/home_bg9.png') },
  { name: 'Details treatment', content: 'We pay careful attention to installation details, such as trimming radar holes, edges and corners wrapping, to ensure that the edges of the installed film do not lift.', url: require('../assets/icon/home_bg9.png') },
  { name: 'Remove protection', content: 'We remove protective measures such as tire covers, windshield covers, and license plate covers, reinstall any removed parts, and apply the vehicle plates.', url: require('../assets/icon/home_bg1.png') },
  { name: 'Final cleaning', content: 'We perform a final cleaning for both interior and exterior vehicle after the installation is complete.', url: require('../assets/icon/home_bg9.png') },
  {
    name: '“Safe-IN” strict self-inspection ', content: `We implement a three-step inspection process:\n1.	The installer self-check.\n2.	The technical supervisor conducts a 2nd review.\n3.	The store manager performs the final inspection.`, url: require('../assets/icon/home_bg9.png')
  },
  { name: 'e-Warranty', content: 'All the product installed will be entered into the e-warranty system.', url: require('../assets/icon/home_bg1.png') },
  { name: 'Hand over', content: 'Hand over the vehicle and warranty card to customer. Kindly remind users of usage precautions.', url: require('../assets/icon/home_bg9.png') },
  { name: 'Regular maintain', content: 'Provide regular after-sales maintenance for customers.', url: require('../assets/icon/home_bg1.png') }])



let dindex = ref(6)
let move = ref(false)
const chooseDot = (i) => {
  dindex.value = i
  move.value = true
  setTimeout(() => {
    move.value = false
  }, 600);
}

const onSwiper = (s) => {
  uswiper.value = s
  console.log(uswiper.value);
};
const onSwiper1 = (s) => {
  uswiper1.value = s
  console.log(uswiper1.value);
};


const onMouseDown = (e) => {
  // 禁止鼠标按下时的拖动行为
}
const onWheel = (event) => {
  if (uswiper.value) {
    const delta = event.deltaY;
    if (delta > 0) {
      uswiper.value.slideNext();
    } else if (delta < 0) {
      uswiper.value.slidePrev();
    }
  }
}
let blog = ref([]);
let shop_info = ref(null);
const choosepopindex = ref(0);
const choosepopindex1 = ref(0);
const choosepop = (index, is_click) => {
  choosepopindex.value = index;

  showpop.value = true;


}
const choosepop1 = (index, is_click) => {
  choosepopindex1.value = index;

}
let newsList = ref([]);
const onSlideChange = (swiper) => {
  activeIndex.value = swiper.realIndex
  nindex.value = swiper.realIndex - 1;
};
const onSlideChange1 = (swiper) => {
  activeIndex.value = swiper.realIndex
  nindex.value = swiper.realIndex - 1;
};
const chooseNav = (i) => {
  if (uswiper.value) {
    uswiper.value.slideTo(i, 500)
  }
  if (uswiper1.value) {
    uswiper1.value.slideTo(i, 500)
  }
  nindex.value = i - 1;
}
const goBack = () => {
  if (uswiper.value) {
    uswiper.value.slideTo(0, 500)
  }
  if (uswiper1.value) {
    uswiper1.value.slideTo(0, 500)
  }
  nindex.value = -1;
}

import Cookies from 'universal-cookie';
const cookies = new Cookies();
let percentage = ref(0)
let time = ref('');
let first = ref(true)

/**
 * 显示实时时间
 */

const nowTime = ref('')
/**
 * 将小于10的数字前面补0
 * @function
 * @param {number} value
 * @returns {string} 返回补0后的字符串
 */
const complement = (value) => {
  return value < 10 ? `0${value}` : value.toString()
}
/**
 * 格式化时间为XXXX年XX月XX日XX时XX分XX秒
 * @function
 * @returns {string} 返回格式化后的时间
 */
const formateDate = () => {
  const time = new Date()
  const year = time.getFullYear()
  const month = complement(time.getMonth() + 1)
  const day = complement(time.getDate())
  return `${year}-${month}-${day}`
}
let timer = 0
/**
 * 设置定时器
 */
let showad = ref(false)
onMounted(() => {
  time.value = setInterval(() => {
    if (percentage.value < 100) {
      percentage.value += 20
    } else {
      first.value = false
      showad.value = true
      clearInterval(time.value)

    }
  }, 1000)
  nowTime.value=formateDate();
setTimeout(function () {
  nowTime.value=formateDate();
},5000)

  getIndex();
  getshopinfos();
  getWarrants();
})
const warrant_years = ref(null);
const getWarrants = async () => {
  try {

    const res = await apigetWarrantys(); // 调用接口

    if (res.code == 1) {
      warrant_years.value = res.data.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }





  } catch (error) {
    console.error(error);
  }
}

const closeAd = () => {
  showad.value = false
}


const getIndex = async () => {
  try {

    const res = await apiGetIndex(); // 调用接口


    blog.value = res.data.blogs;



  } catch (error) {
    console.error(error);
  }
}
onBeforeMount(() => {
  getInfos()
})
const trianer_years = ref(15);
const countries = ref(60);
const trianer = ref(115);
const getInfos = async () => {
  try {

    const res = await apiBaseInfomation(); // 调用接口

    trianer.value = res.data.trianer;
    trianer_years.value = res.data.trianer_years;
    countries.value = res.data.countries;
    useHead({
      title: res.data.title,
      meta: [
        {
          name: "description",
          content: res.data.desc,
        },
        {
          name: "keywords",
          content: res.data.keywords,
        },
      ],
    });
  } catch (error) {
    console.error(error);
  }
}
const getshopinfos = () => {
  if (cookies.get('joyive_shop_info')) {
    shop_info.value = cookies.get('joyive_shop_info');
  }
  const res = apigetinfos({});

  if (res.data) {
    shop_info.value = res.data.login ? res.data.info : null;
    if (shop_info.value) {
      cookies.set('joyive_shop_token', shop_info.value.token);
      cookies.set('joyive_shop_info', JSON.stringify(shop_info.value));
    }
  }

}
const gologin = () => {
  if (confirm("please log in first")) {
    // 用户点击了确定
    router.push({ path: '/Login' });
  }
}


onBeforeUnmount(() => {
  clearInterval(time.value)

  timer = 0
})

</script>
<style lang="less" scoped>
.container1 {
  height: 100vh;
  background-image: url('../assets/icon/load_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .logo {
    width: 198px;
    animation: showlogo 3s infinite;
  }

  .progress_nav {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
  }

  .logo_text {
    width: 285px;
    margin-left: auto;
    margin-right: auto;
  }

  .over_per {
    width: 0%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    overflow: hidden;
    color: #FB471F;
    height: 14px;
    line-height: 14px;
    transition: all ease 0.5s;
  }

  .colf3 {
    color: rgba(255, 255, 255, 0.3);
  }
}

.video_box {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.shadow {
  text-shadow: 1px 2px 2px #333;
}

.mobile {
  display: none;
}

.header_nav {
  position: fixed;
  z-index: 101;
  width: 100%;
  height: 60px;
  top: 0;
  left: 0;
  padding-left: 65px;

  .logo {
    width: 102px;
    height: auto;
    cursor: pointer;
  }

  .more {
    width: 60px;
    height: 60px;
    background: #000000;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: url('../assets/icon/icon_more.png') no-repeat center;
      background-size: 24px auto;
      transition: all ease-in-out 0.4s;
    }

    &.more_ani {
      background-color: transparent;
    }

    &.more_ani::before {
      background-image: url('../assets/icon/icon_close.png');
      background-size: 20px auto;
    }
  }

}

.explore {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 16px;
  text-decoration-line: underline;
  margin-top: 20px;
  display: inline-block;
  font-family: "Metropolis-Italic";
}
.explore1{
  background: #000;
  border-radius: 15px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  font-family: "Metropolis-Regular";
  padding: 0 20px;
  margin-top: 20px;
}
.explore1.botton{
  position: absolute;
  bottom: 8%;
  left: 19%;
}
.explore1.botton1{
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
}
.poa{

  background: #FB471F;
}
.swiper {
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;

  &_item {
    width: 100vw;
    height: 100vh;
    background: #28334e;

    .ra1 {
      display: block;
    }

    &_div {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 1s ease-in-out;
      transition-delay: 0.7s;
      background-color: #28334D;
      padding-left: 312px;

      .more {
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        display: block;
      }

      .more1 {
        width: 75px;
        height: 45px;
        background: #FB471F;
        border-radius: 12px;
        text-align: center;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 45px;
        cursor: pointer;
        display: block;
      }

      .more2 {
        width: 75px;
        height: 45px;
        background: #FB471F;
        border-radius: 12px;
        text-align: center;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 45px;
        position: absolute;
        top: 87px;
        left: 83px;
        cursor: pointer;
      }
    }

    &_div.im {
      background-size: 100% auto;
      background-position: center;
      background-color: #17253F;
    }

    &_div1 {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: relative;
      padding-left: 312px;
    }

    &_div.active {
      opacity: 1;

    }

    .jump_nav {
      position: absolute;
      width: 40%;
      height: 49%;
      display: block;
      bottom: 27%;
      right: 30%;
    }

    .fix_more {
      position: absolute;
      bottom: 89px;
      left: 50%;
      margin-left: -25px;
      z-index: 3;
      color: #FFFFFF;
      line-height: 14px;
      width: 50px;
    }
  }
}

.left_nav {
  position: fixed;
  top: 50%;
  left: 25px;
  width: 187px;
  z-index: 2;
  transform: translateY(-50%);

  &_mobile {
    display: none;
  }

  &::before {
    content: '';
    width: 1px;
    height: 540px;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    left: 11px;
    bottom: -58px;

  }

  div {
    height: 68px;
    cursor: pointer;
    padding-top: 45px;


    p {
      padding-left: 31px;
      padding-right: 45px;
      padding-bottom: 9px;
      color: #FFFFFF;
      display: inline-block;
    }

    &::after {
      content: '';
      width: 80px;
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      display: block;
      transition: all 0.6s;
    }

    &.active p {
      color: #FB471F;
    }

    &.active::after {
      background: #FB471F;
      width: 186px;
    }
  }
}

.home {
  width: 48px;
  height: 48px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  position: fixed;
  left: 11px;
  bottom: 49px;
  z-index: 4;
  transition: all .25s ease 0s;

  span {
    width: 20px;
    height: 14px;
    border-radius: 5px;
    border: 3px solid rgba(255, 255, 255, 0.7);
    display: block;
  }

  &:hover {
    background: #FFFFFF;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
  }

  &:hover span {
    border: 3px solid #FB471F;
  }
}

.down_more {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 12px;
  position: fixed;
  bottom: 40px;
  right: 30px;
  text-align: center;
  z-index: 3;

  .anim {
    width: 36px;
    height: 48px;
    border-radius: 14px;
    border: 2px solid rgba(255, 255, 255, 0.8);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 14px;
    position: relative;
  }

  .animation {
    width: 4px;
    height: 9px;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    animation: down 1s ease infinite;
  }
}

/*弹窗*/
.popup-wrap {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  transition: all 0.4s ease;
  opacity: 1;
  overflow: hidden;
  width: 0;
  transform: none;
}

.popup-wrap.popup-wrap_visible {
  width: 310px;
  transition: all 0.4s ease;
  transform: none;
  &.ad{
    top: 50%;
    left: 50%;
    width: max-content;
    transform: translateX(-50%) translateY(-50%);
    overflow: visible;
    z-index: 120;
  }
}

.popup-mask {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  &.ad{
    z-index: 110;
  }
}

.popup {
  width: 310px;
  height: 100vh;
  font-family: "Metropolis-Medium";

  .close {
    width: 20px;
    height: auto;
    z-index: 2;
    top: 19px;
    right: 19px;
    cursor: pointer;
    transition: all .25s ease 0s;
    transform: scaleY(0);

    &.more_ani {
      animation-delay: 1s;
      transform: scaleY(1);
      transition-duration: 0s;
    }
  }

  .left {
    width: 310px;
    height: 100vh;
    background: #333D57;
    display: inline-block;
    padding-top: 295px;
    text-align: center;
    vertical-align: top;

  }

  .right {
    width: 310px;
    height: 100vh;
    // display: inline-block;
    background: #28334E;
    padding-top: 163px;
    text-align: center;
    vertical-align: top;
    float: right;

  }

  li {
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
  }

  li.active {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
  }

  li.active::after {
    content: '';
    width: 1px;
    height: 11px;
    background: #FFFFFF;
    position: absolute;
    right: 21px;
    top: 50%;
    transform: translateY(-50%);
  }

  li:hover {
    color: #FFFFFF;
  }

  li:hover::after {
    content: '';
    width: 1px;
    height: 11px;
    background: #FFFFFF;
    position: absolute;
    right: 21px;
    top: 50%;
    transform: translateY(-50%);
  }

  li+li {
    margin-top: 30px;
  }
}

.pt100 {
  padding-top: 8%;
}

.dot_nav1 {
  width: 10px;
  height: 10px;
  background: #FB471F;
  border-radius: 50%;
  top: 45.9%;
  left: 54.86%;
  position: absolute;
  cursor: pointer;

  &.phone {
    top: 52%;
    left: 71%;
  }

  &::before {
    content: '';
    width: 20px;
    height: 200px;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }

  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(251, 71, 31, 0.8);
    opacity: 0;
    position: absolute;
    z-index: 3;
    top: 0%;
    left: 0%;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
    animation: rippleAnimation 3s ease infinite;
  }
}

.dot_nav2 {
  width: 10px;
  height: 10px;
  background: #FB471F;
  border-radius: 50%;
  top: 52.7%;
  left: 40.28%;
  position: absolute;
  cursor: pointer;

  &.phone {
    top: 67%;
    left: 27%;
  }

  &::before {
    content: '';
    width: 20px;
    height: 200px;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }

  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(251, 71, 31, 0.8);
    opacity: 0;
    position: absolute;
    z-index: 3;
    top: 0%;
    left: 0%;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
    animation: rippleAnimation 3s ease infinite;
  }
}

.dot_pop {
  width: 350px;
  min-height: 110px;
  background: rgba(40, 51, 78, 0.5);
  box-shadow: 0px 2px 20px 0px rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  border: 1px solid #FB471F;
  position: absolute;
  bottom: 33px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  z-index: 2;

  a {
    display: block;
  }

  img {
    object-fit: cover;
    width: 100px;
    height: 80px;
    border-radius: 5px;
  }
}

.pt101 {
  padding-top: 100px;
  padding-left: 210px;
}

.pt102 {
  padding-top: 100px;
}

.mt70 {
  margin-top: 70px;
}

.w470 {
  width: 470px;
}

.bb {
  margin-top: 55px;
  width: 470px;
  height: 1px;
  background: rgba(255, 255, 255, 0.10);
}

.mt55 {
  margin-top: 55px;
}

.train_img {
  width: 390px;
  height: 505px;
  border-radius: 8px;
  margin-left: 100px;
}

.number_nav {
  text-align: center;
  position: relative;



  p {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 14px;
  }

  .number {
    font-weight: 800;
    font-family: "Metropolis-ExtraBold";
    font-size: 50px;
    color: #FB471F;
    line-height: 50px;
    margin-top: 15px;
  }

  .title {
    width: 90px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 19px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}

.number_nav+.number_nav::before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 47px;
  content: '';
  background: rgba(255, 255, 255, 0.1);
}

.tc_nav {
  width: 100%;
  height: 100%;
  position: relative;
}

.bg_ra {
  width: calc(100vw/ 1440 * 850 * 0.8);
  height: auto;
  position: absolute;
  bottom: 0;
  left: calc(50%);
  margin-left: calc(100vw/ 1440 * -850 * 0.8 / 2);
}

.bg_plotter {
  width: calc(100vw / 1440 * 615 * 0.8);
  height: auto;
  position: absolute;
  bottom: 12%;
  left: 50%;
  margin-left: calc(100vw / 1440 * -615 * 0.8 / 2);
}

.radar {
  width: 162px;
  height: 108px;
  border-radius: 40px;
  border: 23px solid #FB471F;
  position: absolute;
  top: 50%;
  right: -83px;

  .circle,
  .circle1,
  .circle2,
  .circle3,
  .circle4,
  .circle5,
  .circle6,
  .circle7 {
    width: 162px;
    height: 108px;
    border: 1px solid #FB471F;
    border-radius: 23px;
    position: absolute;
    top: -23px;
    left: -8px;
  }

  .circle1,
  .circle2,
  .circle3,
  .circle4,
  .circle5,
  .circle6,
  .circle7 {
    /* animation: circleChange 2s 1s ease-out infinite; */
    animation-name: circleChange;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .circle1 {
    animation-delay: 0.4s;
  }

  .circle2 {
    animation-delay: 0.8s;
  }

  .circle3 {
    animation-delay: 1.2s;
  }

  .circle4 {
    animation-delay: 1.6s;
  }

  .circle5 {
    animation-delay: 2s;
  }

  .circle6 {
    animation-delay: 2.4s;
  }

  .circle7 {
    animation-delay: 2.8s;
  }
}

.img_warranty {
  width: calc(100vw / 1440 * 721);
  height: calc(100vh / 900 * 580);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: all 1s;
  border-radius: 10px;
}

.img_warranty:hover {
  background-size: 110% 110%;
}


.w100 {
  width: 100%;
}

.ico80 {
  width: 80px;
  height: auto;
}

.info_left {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 40px;

  img {
    width: 80px;
    height: auto;
    display: block;
  }

  div {
    margin-top: 20px;
  }

}

.mr0 {
  margin-left: auto;
  margin-right: 0;
}

.pr50 {
  padding-right: 100px;
}

.bg_nav {
  width: 100%;
  height: calc(100vh / 1126 * 646);
  margin-top: 70px;

  p {
    width: 100%;
    height: calc(100vh / 1126 * 646);
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition: all 1s;
  }

  p:hover {
    background-size: 110% 110%;
  }
}

.w1126 {
  width: 78.2%;
}

.w1008 {
  width: 1008px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
}

.tit {
  width: 1008px;
  margin-left: auto;
  margin-right: auto;
}

.news_item {
  width: 400px;
  display: inline-block;
  vertical-align: top;

  &+& {
    margin-left: 30px;
  }

  a {
    display: block;
  }

  .img_nav {
    width: 400px;
    height: 235px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 15px;
    font-size: 16px;
    line-height: 36px;
    color: #FFFFFF;
    transition: all 1s;
    border-radius: 15px;

    .tips {
      width: 74px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 16px;
      color: #FFFFFF;
      margin-right: 15px;
      background: rgba(245, 248, 252, 0.2);
      border-radius: 5px;
    }
  }

  p {
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    margin-top: 25px;
    color: #FFFFFF;
  }

  span {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 24px;
    margin-top: 15px;
  }
}

.news_item:hover {
  color: #FB471F;
}

.news_item:hover span {
  color: rgba(251, 71, 31, 0.8);
}

.news_item:hover p {
  color: #FB471F;
}

.news_item:hover .img_nav {
  background-size: 110% 110%;
}

.w1200 {
  // width: 1200px;
  margin-left: auto;
  margin-right: 0;
  height: 100vh;
  // padding-left: 60px;
}

.h100 {
  height: 100vh;
}

.pc {
  display: block;
}

.mob {
  display: none;
}

.semi_box {
  display: flex;
  justify-content: center;
  /* 水平居中容器 */
  align-items: flex-start;
  /* 使半圆环位于容器顶部 */
  width: 620px;
  height: 310px;
  padding-top: 10px;
  position: relative;
  overflow-y: hidden;
  overflow-x: visible;
  /* 为了演示，设置容器高度为视口高度 */
}

.semi-circle {
  position: relative;
  width: 600px;
  /* 半圆环的宽度 */
  height: 600px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-top-left-radius: 300px;
  border-top-right-radius: 300px;

  display: flex;
  justify-content: center;
  /* 水平方向上居中子元素（初始状态） */
  align-items: center;
  /* 垂直方向上也居中子元素，但后续会通过绝对定位调整 */
  flex-direction: row;
  /* 确保子元素水平排列 */
  /* 新增：设置透视效果，使子元素在三维空间中正确排列 */
  perspective: 1000px;
}

.child {
  width: 8px;
  height: 8px;
  background: #FFFFFF;
  border-radius: 50%;
  transition: transform 0.5s ease-in-out;
  position: absolute;
  /* 使用绝对定位调整子元素位置 */
  /* 新增：设置子元素在三维空间中的位置 */
  transform-style: preserve-3d;
  /* 初始状态下，子元素位于半圆弧的底部中心 */
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  /* 确保子元素在自身中心进行旋转或平移 */
  cursor: pointer;
  /* 添加光标样式以指示可点击 */
  /* 旋转子元素，使其与半圆弧垂直 */
}

.center {
  left: 50%;
  top: 0%;
  /* 在半圆弧的垂直中心 */
  transform: translate(-50%, -100%);
}

.w1027 {
  padding-right: 15px;
}

.fix {
  position: absolute;
  left: 50%;
  top: 180px;
  height: 300px;
  width: 589px;
  transform: translateX(-50%)
}

.technical-specs__menu .dot.org {
  background-color: #FB471F;
}

.technical-specs__menu .label.org {
  color: #FB471F;
}

.technical-specs__menu .dot.org:before {
  background-color: rgba(251, 71, 31, 0.5);
  border: solid 2px #FB471F;
}

.safein_link {
  bottom: 68px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 10;
  font-size: 16px;
  color: #FFFFFF;
  width: 160px;
  line-height: 53px;
  border-radius: 12px;
  border: 1px solid #FFFFFF;
}

@media screen and (max-width: 1270px) {
  .train_img {
    transform: scale(0.6);
    margin-left: 0;
  }

  .img_warranty {
    width: calc(100vh / 1440 * 721);
    height: calc(100vh / 900 * 580);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .news_item {
    width: calc(400px * 0.9);
    display: inline-block;
    vertical-align: top;

    &+& {
      margin-left: 24px;
    }

    a {
      display: block;
    }

    .img_nav {
      width: calc(400px * 0.9);
      height: calc(235px - 0.9);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 15px;
      font-size: 16px;
      line-height: 36px;
      color: #FFFFFF;
      transition: all 1s;

      .tips {
        width: 74px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 16px;
        color: #FFFFFF;
        margin-right: 15px;
        background: rgba(245, 248, 252, 0.2);
        border-radius: 5px;
      }
    }

    p {
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
      margin-top: 25px;
      color: #FFFFFF;
    }

    span {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.8);
      line-height: 24px;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 980px) {
  .container1 {
    .logo {
      width: 99px;
      margin-bottom: 300px;
    }

    .progress_nav {
      position: absolute;
      bottom: 76px;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
    }

    .logo_text {
      width: 220px;
      margin-left: auto;
      margin-right: auto;

      div {
        font-size: 11px;
      }
    }

  }

  .mt30 {
    margin-top: 20px;
  }

  .w1027 {
    padding-right: 0;
  }

  .pc {
    display: none;
  }

  .mob {
    display: block;
  }

  .header_nav {
    position: fixed;
    z-index: 101;
    width: 100%;
    height: 45px;
    top: 0;
    left: 0;
    padding-left: 30px;

    .logo {
      width: 102px;
      height: auto;
      cursor: pointer;
    }

    .more {

      cursor: pointer;

      &::before {
        content: "";
        display: block;
        width: 45px;
        height: 45px;
        background: url('../assets/icon/icon_more.png') no-repeat center;
        background-size: 24px;
      }
    }

  }

  .swiper {
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 1;

    &_item {
      width: 100vw;
      height: 100vh;
      background: #28334e;

      &_div {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all ease 2s;
        transition-delay: 0.7s;
        background-color: #28334D;
        padding-left: 60px;



        .more1 {
          width: 75px;
          height: 45px;
          background: #FB471F;
          border-radius: 12px;
          text-align: center;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 45px;
          cursor: pointer;
        }

        .more2 {
          width: 75px;
          height: 45px;
          background: #FB471F;
          border-radius: 12px;
          text-align: center;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 45px;
          position: absolute;
          top: 87px;
          left: 83px;
          cursor: pointer;
        }
      }

      &_div1 {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: relative;
        padding-left: 60px;
      }

      &_div.active {
        opacity: 1;

      }
    }

    .jump_nav {
      position: absolute;
      width: 40%;
      height: 49%;
      display: block;
      bottom: 27%;
      right: 30%;
    }

    .fix_more {
      position: absolute;
      bottom: 89px;
      left: 50%;
      margin-left: -25px;
      z-index: 3;
      color: #FFFFFF;
      line-height: 14px;
      width: 50px;
    }
  }

  .left_nav {
    position: fixed;
    top: 50%;
    left: 25px;
    width: 30px;
    z-index: 2;
    transform: translateY(-50%);
    display: none;

    &_mobile {
      position: fixed;
      display: inline-block;
      top: 50%;
      left: 25px;
      width: 30px;
      z-index: 2;
      transform: translateY(-50%);

      p {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.6);
      }

      div+div {
        margin-top: 15px;
      }

      .active p {
        background: #fff;
      }
    }

    &::before {
      content: '';
      width: 1px;
      height: 540px;
      position: absolute;
      background: rgba(255, 255, 255, 0.2);
      left: 11px;
      bottom: -58px;

    }

    div {
      height: 68px;
      cursor: pointer;
      padding-top: 45px;

      p {
        padding-left: 31px;
        padding-right: 45px;
        padding-bottom: 9px;
        color: #FFFFFF;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        display: inline-block;
      }

      &.active p {
        border-bottom: 1px solid #FB471F;
        color: #FB471F;
      }
    }
  }

  .home {
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    position: fixed;
    left: 11px;
    bottom: 49px;
    z-index: 4;

    span {
      border: 3px solid #FB471F;
    }
  }

  .down_more {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 12px;
    position: absolute;
    bottom: 40px;
    right: 30px;
    text-align: center;
    z-index: 3;

    .anim {
      width: 36px;
      height: 48px;
      border-radius: 14px;
      border: 4px solid rgba(255, 255, 255, 0.8);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 14px;
      position: relative;
    }

    .animation {
      width: 4px;
      height: 9px;
      background: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 5px;
      left: 50%;
      transform: translateX(-50%);
      animation: down 1s ease infinite;
    }
  }

  .explore {
    font-size: 12px;
    line-height: 12px;
    margin-top: 20px;

  }

  .popup-wrap {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    transition: all 0.4s ease;
    opacity: 1;
    overflow: hidden;
    width: 0;
    transform: none;
  }

  .popup-wrap.popup-wrap_visible {
    width: 50%;
    transition: all 0.4s ease;
    transform: none;
  }

  .popup {
    width: 100%;
    height: 100vh;
    z-index: 100;

    .close {
      width: 20px;
      height: auto;
      position: absolute;
      z-index: 2;
      top: 19px;
      right: 19px;
      cursor: pointer;
    }

    .left {
      width: 50%;
      height: 100vh;
      background: #333D57;
      display: inline-block;
      padding-top: 295px;
      text-align: center;
      vertical-align: top;

    }

    .right {
      width: 100%;
      height: 100vh;
      display: inline-block;
      background: #28334E;
      padding-top: 163px;
      text-align: center;
      vertical-align: top;
    }

    li {
      position: relative;
      cursor: pointer;
    }

    li.active {
      color: #FFFFFF;
    }

    li.active::after {
      content: '';
      width: 1px;
      height: 11px;
      background: #FFFFFF;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    li:hover {
      color: #FFFFFF;
    }

    li:hover::after {
      content: '';
      width: 1px;
      height: 11px;
      background: #FFFFFF;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    li+li {
      margin-top: 30px;
    }
  }

  .pt100 {
    padding-top: 60px;
    padding-right: 15px;
    padding-left: 45px;
  }

  .dot_pop {
    width: 175px;
    min-height: 55px;
    background: rgba(40, 51, 78, 0.5);
    box-shadow: 0px 2px 20px 0px rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    border: 1px solid #FB471F;
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;

    img {
      object-fit: cover;
      width: 50px;
      height: 40px;
      border-radius: 3px;
    }
  }

  .pt101 {
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 30px;
  }

  .pt102 {
    padding-top: 30px;
  }

  .mt70 {
    margin-top: 25px;
  }

  .w470 {
    width: 100%;
    padding-right: 15px;
  }

  .mt30 {
    margin-top: 20px;
  }

  .lh40 {
    line-height: 26px;
  }

  .bb {
    margin-top: 20px;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.10);
  }

  .mt55 {
    margin-top: 20px;
  }

  .number_nav {
    text-align: center;
    position: relative;



    p {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;
    }

    .number {
      font-weight: 800;
      font-size: 25px;
      color: #FB471F;
      line-height: 25px;
      margin-top: 8px;
    }

    .title {
      width: 90px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.8);
      line-height: 14px;
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .number_nav+.number_nav::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 47px;
    content: '';
    background: rgba(255, 255, 255, 0.1);
  }

  .bg_ra {
    width: calc(100vw / 1440 * 425);
    height: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -50%;
  }

  .bg_plotter {
    width: calc(100vw / 1440 * 300);
    height: auto;
    position: absolute;
    bottom: 2%;
    left: 50%;
    margin-left: -50%;
  }

  .radar {
    width: 162px;
    height: 108px;
    border-radius: 40px;
    border: 23px solid #FB471F;
    position: absolute;
    top: 50%;
    right: -83px;

    .circle,
    .circle1,
    .circle2,
    .circle3,
    .circle4,
    .circle5,
    .circle6,
    .circle7 {
      width: 162px;
      height: 108px;
      border: 1px solid #FB471F;
      border-radius: 23px;
      position: absolute;
      top: -23px;
      left: -8px;
    }

    .circle1,
    .circle2,
    .circle3,
    .circle4,
    .circle5,
    .circle6,
    .circle7 {
      /* animation: circleChange 2s 1s ease-out infinite; */
      animation-name: circleChange;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .circle1 {
      animation-delay: 0.4s;
    }

    .circle2 {
      animation-delay: 0.8s;
    }

    .circle3 {
      animation-delay: 1.2s;
    }

    .circle4 {
      animation-delay: 1.6s;
    }

    .circle5 {
      animation-delay: 2s;
    }

    .circle6 {
      animation-delay: 2.4s;
    }

    .circle7 {
      animation-delay: 2.8s;
    }
  }

  .img_warranty {
    width: 295px;
    height: 234px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .w100 {
    width: 100%;
  }

  .ico80 {
    width: 80px;
    height: auto;
  }

  .info_left {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.9);
    line-height: 25px;

    img {
      width: 40px;
      height: auto;
      display: block;
    }

    div {
      margin-top: 20px;
    }

  }

  .mr0 {
    margin-left: auto;
    margin-right: 0;

    &.mob_mr0 {
      margin-right: 30px;
    }
  }

  .pr50 {
    padding-right: 50px;
  }

  .mob_pr30 {
    padding-right: 30px;
  }

  .bg_nav {
    width: 100%;
    height: calc(100vh / 1126 * 646 * 0.8);
    margin-top: 35px;

    p {
      width: 100%;
      height: calc(100vh / 1126 * 646 * 0.8);
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transition: all 1s;
    }

    p:hover {
      background-size: 110% 110%;
    }
  }

  .w1126 {
    width: 100%;
    // padding-right: 15px;
  }

  .w1008 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
  }

  .tit {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .news_item {
    width: 100%;
    display: block;
    color: #FFFFFF;

    &+& {
      margin-left: 0;
      margin-top: 15px;
    }

    .img_nav {
      width: 100%;
      height: 150px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 15px;
      font-size: 16px;

      line-height: 36px;

      .tips {
        width: 74px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 16px;
        margin-right: 15px;
        background: rgba(245, 248, 252, 0.2);
        border-radius: 5px;
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      margin-top: 10px;
    }

    span {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.8);
      line-height: 16px;
      margin-top: 5px;
    }
  }

  .news_item:hover {
    color: #FB471F;
  }

  .news_item:hover span {
    color: rgba(251, 71, 31, 0.8);
  }

  .news_item:hover p {
    color: #FB471F;
  }

  .w1200 {
    // width: 100%;
    margin-left: auto;
    margin-right: 0;
    height: 100vh;

  }

  .h100 {
    height: 100vh;
  }

  .mt80 {
    margin-top: 20px;
  }

  .train_img {
    width: 165px;
    height: 200px;
    border-radius: 4px;
    transform: scale(1);
  }

  .fix {
    position: absolute;
    left: 40%;
    top: 206px;
    height: 300px;
    width: 300px;
    transform: translateX(-50%)
  }

  .safein_link {
    bottom: 88px;
    left: 40%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 10;
    font-size: 14px;
    color: #FFFFFF;
    width: 120px;
    line-height: 46px;
    border-radius: 12px;
    border: 1px solid #FFFFFF;
  }
}

.maxw800 {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@keyframes showlogo {

  // 0% {
  //     opacity: 0;
  //     transform: scale(0, 0);
  // }
  // 50% {
  //     opacity: 1;
  //     transform: scale(1, 1);
  // }
  // 100% {
  //     opacity: 0;
  //     transform: scale(0, 0);
  // }
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(1.5, 1.5);
    // -webkit-filter: blur(90px);
  }

  50% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(0.75, 0.75);
    // -webkit-filter: blur(0px);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(1.5, 1.5);
    // -webkit-filter: blur(90px);
  }
}

.mt120 {
  margin-top: 120px;
}

.coleb5 {
  color: #EB5525;
}

.w300 {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
}

.read_more {
  width: 95px;
  border: 1px solid #C0C5C5;
  line-height: 18px;
  border-radius: 9px;
  font-size: 12px;
  color: #fff;
  display: block;
}

.img290 {
  width: 290px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ad_pop {
  position: relative;

  .ad_img {
    width: 70vw;
    height: auto;
    position: relative;
    &_mob{
      width: 293px;
    }
  }

  .ad_close {
    width: 40px;
    height: auto;
    top: -10px;
    right: -10px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    &_mob{
      right: 0;
      width: 40px;
    height: auto;
    top: -10px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    }
  }
}
</style>
